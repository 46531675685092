import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, withModifiers as _withModifiers, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "timi-machine-container" }
const _hoisted_2 = { class: "form-layout" }
const _hoisted_3 = { class: "timi-form-item col-6" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "timi-form-item col-6" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "timi-form-item col-6" }
const _hoisted_8 = ["placeholder"]
const _hoisted_9 = { class: "timi-form-item col-6" }
const _hoisted_10 = ["placeholder"]
const _hoisted_11 = { class: "timi-form-item col-6" }
const _hoisted_12 = { class: "timi-form-item col-6" }
const _hoisted_13 = { class: "form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_IconButton = _resolveComponent("IconButton")!
  const _component_SelectSingle = _resolveComponent("SelectSingle")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_IconButton, {
      icon: _ctx.store.getters.svgIcons.arrowbackward,
      class: "go-back-btn",
      label: _ctx.$t('button.back'),
      dense: true,
      onOnClick: _ctx.handleGoBack
    }, null, 8, ["icon", "label", "onOnClick"]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("form", {
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.handleSubmitPrevent && _ctx.handleSubmitPrevent(...args)), ["prevent"]))
      }, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.label')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('label.label'),
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.title) = $event))
          }, null, 8, _hoisted_4), [
            [_vModelText, _ctx.form.title]
          ])
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.number')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: _ctx.$t('placeholder.numberExample'),
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.number) = $event))
          }, null, 8, _hoisted_6), [
            [_vModelText, _ctx.form.number]
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.hourlyRate')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: 0.00,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.hourlyRate) = $event))
          }, null, 8, _hoisted_8), [
            [_vModelText, _ctx.form.hourlyRate]
          ])
        ]),
        _createElementVNode("div", _hoisted_9, [
          _createElementVNode("label", null, _toDisplayString(_ctx.$t('label.runtimeTarget')), 1),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            placeholder: 0.00,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.runtimeTarget) = $event))
          }, null, 8, _hoisted_10), [
            [_vModelText, _ctx.form.runtimeTarget]
          ])
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_SelectSingle, {
            placeholder: _ctx.$t('placeholder.selectDepartment'),
            item: { label: _ctx.$t('label.department')},
            options: _ctx.departments,
            value: { value: _ctx.form.department ? _ctx.form.department.uid : 0, text: _ctx.form.department ? _ctx.form.department.title : '' },
            disabled: false,
            onOnChange: _cache[4] || (_cache[4] = (item, value)=>_ctx.form.department.uid = value)
          }, null, 8, ["placeholder", "item", "options", "value"])
        ]),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_SelectSingle, {
            placeholder: _ctx.$t('placeholder.selectWorkarea'),
            item: { label: _ctx.$t('workarea.title')},
            options: _ctx.workareas,
            value: { value: _ctx.form.workarea ? _ctx.form.workarea.uid : 0 , text: _ctx.form.workarea ? _ctx.form.workarea.title : '' },
            disabled: false,
            onOnChange: _cache[5] || (_cache[5] = (item, value) => _ctx.form.workarea.uid = value)
          }, null, 8, ["placeholder", "item", "options", "value"])
        ])
      ], 32)
    ]),
    _createElementVNode("div", _hoisted_13, [
      (!_ctx.editMode)
        ? (_openBlock(), _createBlock(_component_Button, {
            key: 0,
            text: _ctx.$t('button.addMachine'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleCreate
          }, null, 8, ["text", "onOnClick"]))
        : (_openBlock(), _createBlock(_component_Button, {
            key: 1,
            text: _ctx.$t('button.save'),
            primary: true,
            raised: true,
            onOnClick: _ctx.handleSave
          }, null, 8, ["text", "onOnClick"]))
    ])
  ]))
}