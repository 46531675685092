
import { defineComponent } from 'vue';
import { DepartmentController, MachineController, WorkareaController } from '@/controller/';
import { Button, IconButton, SelectSingle } from '@/ui/index';
import store from "@/store/index";

export default defineComponent({
    name: "MachineForm",
    components: { Button, IconButton, SelectSingle },
    data() {
        return{
            store,
            editMode: false,
            form:{
                uid: 0,
                title: "",
                number: "",
                hourlyRate:  0,
                runtimeTarget: 0,
                department: {
                    uid: 0,
                    title: ""
                },
                workarea: {
                    uid: 0,
                    title: ""
                }
            },
            departments: [] as Array<any>,
            workareas: [] as Array<any>
        }
    },
    created() {
        if (this.$route.params.id && this.$route.params.id != "0" ) {
            this.editMode = true;
            this.handleGetMachine(this.$route.params.id);
        } else {
            this.editMode = false;
        }
        this.handleGetDepartments();
        this.handleGetWorkareas();
    },
    methods:{
        handleGoBack() {
            this.$router.go(-1);
        },
        handleSubmitPrevent(){
            if (this.editMode) {
                this.handleSave();
            } else {
                this.handleCreate();
            }
        },
        async handleGetMachine(uid:any) {
            const res = await MachineController.getMachineById(uid);
            if (!res.error) {
                this.form = res.machine;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleGetDepartments() {
            const res = await DepartmentController.fetchDepartments();
            if(!res.error){
                this.departments = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }        },
        async handleGetWorkareas() {
            const res = await WorkareaController.fetchWorkareas();
            if(!res.error){
                this.workareas = res.items;
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleCreate() {
            const res = await MachineController.createMachine(this.form);
            if (!res.error) {
                this.$notify(this.$t("success.machineCreated"), { position: "top" });
                this.$router.push("/administration/machines");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        },
        async handleSave() {
            const res = await MachineController.updateMachine(this.form);
            if (!res.error) {
                this.form = res.machine;
                this.$notify(this.$t("success.machineUpdated"), { position: "top" });
                this.$router.push("/administration/machines");
            } else {
                this.$notify(this.$t(res.error.message), { position: "right", type: "error" });
            }
        }
    }
})
